import React from "react";
import { Container } from "react-bootstrap";
import { HomeSectionStyled } from "./home.styled";
import homeImg from "../../assets/home_v2.png";

const Home = () => {
  return (
    <HomeSectionStyled
      id={"home"}
      className="home-sec d-flex align-items-center"
      style={{
        background: `url(${homeImg}) center center no-repeat`,
        height: "calc(100vh - 100px)",
        backgroundSize: "cover",
        backgroundAttachment: "scroll", // Cambiado de 'fixed' a 'scroll' para mejor responsividad
      }}
    >
      <Container className="home-container animate__animated animate__lightSpeedInLeft">
        <h1>Bienvenidos a KQTECH</h1>
        <h2>
          Innovación Meteorológica y Desarrollo en la Nube para el Futuro
        </h2>
          {/* <CustomButton type="btn-on-img">GET STARTED</CustomButton> */}
      </Container>
    </HomeSectionStyled>
  );
};

export default Home;
