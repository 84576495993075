import React from "react";
import {Col, Image} from "react-bootstrap";
import SectionContainer from "../../components/SectionContainer";
import AboutBlocks from "./AboutBlocks";
import AboutSponsor from "./AboutSponsor";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {RowReversedStyled} from "./About.styled";
import {paragraphs} from "./aboutData";
import aboutImage from "../../assets/about.jpg";

const About = () => {
  return (
    <>
      <SectionContainer id={"about"} secName={"about-sec"}>
        <RowReversedStyled className="my-2">
          <Col lg={6} className="mb-3">
            <h3>
           Nosotros
            </h3>
            {/* <p className="fst-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
            <ul>
              {paragraphs.map((par, idx) => (
                <li className="mb-3" key={idx}>
                  <AiOutlineCheckCircle
                    className="me-1"
                    size={24}
                    color={"var(--ds-blood)"}
                  />
                  {par}
                </li>
              ))}
            </ul>
            <p style={{ textAlign: 'justify' }}>
              En KQTECH, combinamos experiencia técnica y un profundo entendimiento de las necesidades locales 
              para ofrecer soluciones tecnológicas que transforman la forma en que nuestros clientes gestionan 
              la información ambiental y climática. Nos caracterizamos por una visión colaborativa, trabajando 
              de cerca con instituciones y empresas para desarrollar herramientas que no solo resuelvan desafíos 
              actuales, sino que también anticipen futuros cambios en el sector.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Nuestra empresa está orientada a la innovación continua, fomentando un ambiente de investigación y 
              desarrollo que nos permite mantenernos a la vanguardia tecnológica. Creemos en la importancia de generar
              impacto positivo en las comunidades, aportando valor a través de soluciones sostenibles y adaptadas al 
              contexto peruano.
           </p>
          </Col>
          <Col lg={6} className="mb-3">
            <Image
              src={aboutImage}
              alt="about-img"
              width="100%"
              height="auto"
            />
          </Col>
        </RowReversedStyled>
        <AboutBlocks />
      </SectionContainer>
      <AboutSponsor />
    </>
  );
};

export default About;
