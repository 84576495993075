import sponser1 from "../../assets/Meteomatics.jpeg";
import sponser2 from "../../assets/client-2.png";
import sponser3 from "../../assets/client-3.png";
import sponser4 from "../../assets/client-4.png";
import sponser5 from "../../assets/client-5.png";
import sponser6 from "../../assets/client-6.png";
export const paragraphs = [
  // "Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  // "Duis aute irure dolor in reprehenderit in voluptate velit.",
  // "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.",
];
export const cards = [
  {
    title: "Misión",
    text: "Somos especialistas en meteorología, hidrología y medio ambiente. Desarrollamos productos innovadores que optimizan el uso de la información, ofreciendo soluciones personalizadas que integran tecnología de vanguardia para lograr resultados óptimos, con un enfoque en las necesidades del contexto peruano.",
    number: "01",
  },
  {
    title: "Visión",
    text: "Ser el líder en la provisión de información, servicios y sistemas que impulsen a tomar decisiones informadas y obtener soluciones efectivas en los diferentes sectores de la actividad económica, promoviendo un desarrollo sostenible y adaptado a las necesidades cambiantes del entorno tecnológico y ambiental.",
    number: "02",
  },
  // {
  //   title: "Objetivos",
  //   text: "Desarrollar plataformas que mejoren la toma de decisiones y proporcionen soluciones personalizadas. Ampliar servicios en la nube para optimizar el uso de datos. Establecer alianzas estratégicas que fomenten la innovación y promuevan un desarrollo sostenible, adaptado a las necesidades del entorno ambiental y tecnológico.",   
  //   number: "03",
  // },
  {
    title: "Objetivos",
    text: (
      <ol>
        <li>Desarrollar plataformas que mejoren la toma de decisiones y proporcionen soluciones personalizadas.</li>
        <li>Ampliar servicios en la nube para optimizar el uso de datos.</li>
        <li>Establecer alianzas estratégicas que fomenten la innovación y un desarrollo sostenible.</li>
      </ol>
    ),
    number: "03",
  }
  
  
  
];
export const sponsors = [
  sponser1,
  sponser2,
  sponser3,
  sponser4,
  sponser5,
  sponser6,
];


