// import styled from "styled-components";
// import {themeList} from "../../contexts/ThemeContext/themeList";

// export const SectionHeadStyled = styled.div`
//   position: relative;
//   .back-head {
//     color: ${({theme: {theme}}) =>
//       theme === themeList.light ? "var(--ds-light-2)" : "var(--ds-dark-3)"};
//     transition: all 0.5s;
//     font-size: 50px;
//     font-weight: 800;
//     text-transform: uppercase;
//     position: absolute;
//     top: -15px;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 2;
//   }
//   .sec-head {
//     transition: all 0.5s;
//     font-size: 30px;
//     text-transform: uppercase;
//     font-weight: 700;
//     color: ${({theme: {theme}}) =>
//       theme === themeList.light ? "var(--ds-dark-3)" : "var(--ds-light-2)"};
//     z-index: 3;
//     position: relative;
//   }
// `;

import styled from "styled-components";

export const SectionHeadStyled = styled.div`
  position: relative;

  .back-head {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6rem; /* Ajusta el tamaño aquí */
    color: rgba(255, 255, 255, 0.1); /* Ajusta la opacidad para que sea más sutil */
    z-index: -1; /* Asegura que el texto de fondo esté detrás */
    white-space: nowrap; /* Evita que el texto se corte */
  }

  .sec-head {
    font-size: 2.5rem;
    color: #333;
    z-index: 1; /* Asegura que el título principal esté encima */
  }

  p {
    font-size: 1rem;
    color: #666;
  }

  @media (max-width: 768px) {
    .back-head {
      font-size: 3rem; /* Reducir el tamaño del texto de fondo en pantallas más pequeñas */
    }

    .sec-head {
      font-size: 2rem;
    }
  }
`;
