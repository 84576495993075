import {IoIosPeople} from "react-icons/io";
import {IoNewspaperOutline} from "react-icons/io5";
import {CiBasketball} from "react-icons/ci";
import {TbPaperclip, TbWorld} from "react-icons/tb";
import {BiBuilding} from "react-icons/bi";
export const services = [
  {
    iconName: IoNewspaperOutline,
    title: "Drones Meteorológicos",
    text: "Equipos diseñados para recolectar datos atmosféricos precisos, incluso en áreas de difícil acceso, mejorando la calidad de los pronósticos meteorológicos.",
  },
  {
    iconName: IoIosPeople,
    title: "Sensores de Precisión Meteorológica",
    text: "Sensores avanzados para medir variables climáticas como temperatura, humedad, presión, dirección y velocidad del viento.",
  },
  {
    iconName: CiBasketball,
    title: "Estaciones Automáticas Meteorológicas",
    text: "Soluciones completas para la medición y monitoreo automático de condiciones atmosféricas en tiempo real.",
  },
  {
    iconName: TbPaperclip,
    title: "Monitoreo Atmosférico con Drones",
    text: "Servicio de levantamiento de datos atmosféricos con drones para análisis de condiciones extremas y predicciones específicas.",
  },
  {
    iconName: TbWorld,
    title: "Instalación y Mantenimiento de Equipos Meteorológicos",
    text: "Asesoría, instalación, configuración y calibración de estaciones meteorológicas, garantizando su óptimo funcionamiento.",
  },
  {
    iconName: BiBuilding,
    title: "Pronósticos Meteorológicos Personalizados",
    text: "Elaboración de pronósticos detallados adaptados a sectores como agricultura, minería y turismo, utilizando datos recolectados con drones y estaciones meteorológicas.",
  },
];
